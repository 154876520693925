import Helmet from 'react-helmet'
import * as React from 'react'
import styled, { injectGlobal, ThemeProvider } from 'styled-components'
import Navbar from './Navbar'
import Footer from './Footer'
import baseStyles, { theme } from '../utils/theme'

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  ${baseStyles}
`

interface Props {
  bg?: string
  header?: 'light' | 'dark'
  children: React.ReactNode
}

interface IMainProps {
  bg?: string
}

const Main = styled<IMainProps, any>('div')`
  background-color: ${props => props.bg || 'none'};
  position: relative;
  overflow-x: hidden;
`

const localData = {
  '@context': 'http://schema.org',
  '@type': 'LocalBusiness',
  name: 'Turboclicks',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'PO Box 93',
    addressLocality: 'Kedron',
    addressRegion: 'QLD',
    postalCode: '4031'
  },
  email: 'contact@turboclicks.com.au',
  telePhone: '0491 114 014',
  url: 'https:/www.turboclicks.com.au',
  paymentAccepted: ['credit card', 'invoice', 'paypal'],
  openingHours: 'Mo,Tu,We,Th,Fr 09:00-17:00',
  openingHoursSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '09:00',
      closes: '17:00'
    }
  ],
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '-27.404236',
    longitude: '153.032336'
  },
  priceRange: '$$'
}

const MainLayout = ({ children, bg, header }: Props) => (
  <ThemeProvider theme={theme}>
    <Main bg={bg}>
      <Helmet>
        <meta charSet="utf-8" />

        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/assets/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/assets/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/assets/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/assets/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/assets/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/assets/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="publisher"
          href="https://plus.google.com/b/117472728127973403290/117472728127973403290"
        />

        <link rel="canonical" href="https://www.turboclicks.com.au/" />
        <link
          rel="publisher"
          href="https://plus.google.com/u/0/117472728127973403290"
        />

        <meta
          property="article:publisher"
          content="https://www.facebook.com/turboclicksdigital/"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(localData) }}
        />

        <link
          rel="alternate"
          type="application/rss+xml"
          title="Turboclicks &raquo; Feed"
          href="https://studio-culture.com.au/rss.xml"
        />

        <meta
          name="description"
          content="Want to get new customers? Find out how you can double your business sales with strategic digital growth marketing."
        />

        <meta
          name="image"
          content="https://www.turboclicks.com.au/static/gold-coast-d1186323e6043e1aacb446aed0133b87-c7352.jpg"
        />

        <meta
          itemProp="name"
          content="Turboclicks - Brisbane's digital growth strategists"
        />
        <meta
          itemProp="description"
          content="Want to get new customers? Find out how you can double your business sales with strategic digital growth marketing."
        />
        <meta
          itemProp="image"
          content="https://www.turboclicks.com.au/static/gold-coast-d1186323e6043e1aacb446aed0133b87-c7352.jpg"
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Turboclicks - Brisbane's digital growth strategists"
        />
        <meta
          name="twitter:description"
          content="Want to get new customers? Find out how you can double your business sales with strategic digital growth marketing."
        />
        <meta name="twitter:site" content="@turboclicksau" />
        <meta name="twitter:creator" content="@turboclicksau" />

        <meta
          name="og:title"
          content="Turboclicks - Brisbane's digital growth strategists"
        />
        <meta
          name="og:description"
          content="Want to get new customers? Find out how you can double your business sales with strategic digital growth marketing."
        />
        <meta
          name="og:image"
          content="https://www.turboclicks.com.au/static/gold-coast-d1186323e6043e1aacb446aed0133b87-c7352.jpg"
        />
        <meta name="og:url" content="https://www.turboclicks.com.au/" />
        <meta name="og:site_name" content="Turboclicks" />
        <meta name="og:locale" content="en_AU" />
        <meta name="fb:admins" content="255149924817598" />
        <meta name="og:type" content="website" />
      </Helmet>
      <Navbar theme={header} />
      {children}
      <Footer />
    </Main>
  </ThemeProvider>
)

export default MainLayout
