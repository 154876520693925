import { transparentize, darken } from 'polished'
import styled, { css } from 'styled-components'

const inverted = css`
  background-color: ${props => props.theme.colors.purple};
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: ${props => darken(0.025, props.theme.colors.purple)};
  }
`

interface IButtonProps {
  inverted: boolean
  full: boolean
}

export const Button = styled<IButtonProps, any>('button')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  height: 50px;
  vertical-align: top;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 6px 18px 0
    ${props => transparentize(0.85, props.theme.colors.dark)};
  width: ${props => (props.full ? '100%' : 'initial')};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 13px;

  background-color: #fff;
  color: ${props => props.theme.colors.primary};
  padding: 0 1.5rem;
  height: 48px;

  @media (min-width: 940px) {
    font-size: 14px;
    height: 60px;
    padding: 0 2rem;
  }
  &:hover,
  &:focus,
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 18px 0
      ${props => transparentize(0.75, props.theme.colors.dark)};
  }
  @media (min-width: 1180px) {
    padding: 0 2.25rem;
  }

  ${props =>
    props.inverted &&
    css`
      ${inverted};
    `};

  /* .c-btn--small {
  height: 40px;
  padding: 0 1rem;
}
@media (min-width: 940px) {
  .c-btn--small {
    padding: 0 1.5rem;
    height: 48px;
  }
}
.c-btn--secondary {
  border: 1px solid #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.c-btn--secondary:hover,
.c-btn--secondary:focus,
.c-btn--secondary:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
} */
`

export default Button
