import * as React from 'react'

import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterList,
  FooterInfo
} from './styles'
import Logo from '../Logo'

import Instagram from 'react-icons/lib/fa/instagram'
import Facebook from 'react-icons/lib/fa/facebook'
import Google from 'react-icons/lib/fa/google-plus'
import Twitter from 'react-icons/lib/fa/twitter'
import { Flex } from 'grid-styled'

import footerLinks from './footer'

const Footer: React.SFC = () => (
  <StyledFooter>
    <FooterInfo>
      <Logo color="primary" />
      <p>
        © 2018 Turboclicks, Pty Ltd. All rights reserved. <br /> Proudly made in
        Brisbane
      </p>
      <SocialMedia>
        <a target="_blank" href="https://instagram.com/turboclicksdigital">
          <Instagram />
        </a>
        <a target="_blank" href="https://facebook.com/turboclicksdigital">
          <Facebook />
        </a>
        <a target="_blank" href="https://plus.google.com/117472728127973403290">
          <Google />
        </a>
        <a target="_blank" href="https://twitter.com/turboclicksau">
          <Twitter />
        </a>
      </SocialMedia>
    </FooterInfo>
    <Flex>
      {footerLinks.map(item => (
        <FooterList key={item.text}>
          <span>{item.text}</span>
          {item.links.map(link => (
            <FooterLink key={link.text} to={link.to || '#'}>
              {link.text}
            </FooterLink>
          ))}
        </FooterList>
      ))}
    </Flex>
  </StyledFooter>
)

export default Footer
