import * as React from 'react'
import styled from 'styled-components'

interface ISvgProps {
  rotate: string
}

const Svg = styled<ISvgProps, any>('svg')`
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  width: 24px;
  height: 24px;

  @media (max-width: 640px) {
    width: 60px;
  }
  transform: ${props => (props.rotate ? `rotate(${props.rotate})` : 'none')};
`

const Arrow = ({ ...rest }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...rest}
  >
    <path d="M25.4 30.4c-.8.8-2 .8-2.8 0l-9-9c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l7.6 7.6 7.6-7.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-9 9z" />
  </Svg>
)

export default Arrow
