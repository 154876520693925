import styled from 'styled-components'
import { Link } from 'gatsby'
import { yPad, xPad } from '../../utils/theme'

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.light};
  ${yPad};
  border-top: 1px solid #eee;

  @media (min-width: 940px) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }
`

export const FooterList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  /* color: #fff; */

  span {
    font-weight: 700;
    margin-bottom: 0.5rem;
    cursor: default;
  }
`

export const FooterLink = styled(Link)`
  opacity: 0.7;
  color: ${props => props.theme.colors.dark};
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    opacity: 1;
  }
`

export const FooterInfo = styled.div`
  ${xPad};
  padding-left: 0 !important;
  margin-bottom: 3.5rem;

  @media (max-width: 940px) {
    text-align: center;
  }

  > svg {
    width: 220px;
    opacity: 0.9;
  }
  p {
    /* color: #fff; */
    opacity: 0.7;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
`

export const SocialMedia = styled.div`
  svg {
    /* color: #fff; */
    width: 24px;
    height: auto;
    margin-right: 1rem;
    opacity: 0.7;
    transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      opacity: 1;
    }
  }
`
