export default [
  {
    dropdown: [
      {
        links: [
          {
            text: 'Social Media',
            to: '/services/social-media-marketing'
          },
          {
            text: 'Google Ads',
            to: '/services/google-ppc-advertising'
          },
          {
            text: 'Remarketing',
            to: '/services/online-remarketing-australia'
          },
          {
            text: 'SEO',
            to: '/services/search-engine-optimisation'
          }
        ],
        text: 'Digital Growth'
      },
      {
        links: [
          {
            text: 'Website Development',
            to: '/services/website-development-brisbane'
          },
          {
            text: 'Web Hosting',
            to: '/services/brisbane-website-hosting'
          },
          {
            text: 'App Development',
            to: '/services/business-application-development'
          }
        ],
        text: 'Web Services'
      }
    ],
    hasSubSections: true,
    text: 'Services'
  },
  {
    dropdown: [
      {
        text: 'About',
        to: '/about'
      },
      {
        text: 'Testimonials',
        to: '/testimonials'
      },
      {
        text: 'FAQs',
        to: '/faqs'
      }
    ],
    text: 'Why Us'
  },
  {
    text: 'Case Studies',
    to: '/case-studies'
  },
  {
    text: 'Blog',
    to: '/blog'
  },
  {
    text: 'Contact',
    to: '/contact'
  }
]
