export default [
  {
    links: [
      {
        text: 'About',
        to: '/about'
      },
      {
        text: 'Testimonials',
        to: '/testimonials'
      },
      {
        text: 'FAQs',
        to: '/faqs'
      },
      {
        text: 'Case Studies',
        to: '/case-studies'
      },
      {
        text: 'Blog',
        to: '/blog'
      },
      {
        text: 'Contact',
        to: '/contact'
      }
    ],
    text: 'Company'
  },
  {
    links: [
      {
        text: 'SEO',
        to: '/services/search-engine-optimisation'
      },
      {
        text: 'Social Media',
        to: '/services/social-media-marketing'
      },
      {
        text: 'Google Ads',
        to: '/services/google-ppc-advertising'
      },
      {
        text: 'Web Hosting',
        to: '/services/brisbane-website-hosting'
      },
      {
        text: 'Website Development',
        to: '/services/website-development-brisbane'
      },
      {
        text: 'Remarketing',
        to: '/services/online-remarketing-australia'
      },
      {
        text: 'App Development',
        to: '/services/business-application-development'
      }
      // {
      //   text: 'Turboclicks Suite',
      //   to: '/products/employees'
      // }
    ],
    text: 'Services'
  },
  {
    links: [
      {
        text: 'Contact Turboclicks',
        to: '/contact'
      },
      {
        text: 'Chat Live',
        to: '/contact'
      },
      {
        text: '0491 114 014'
      }
    ],
    text: 'Support'
  }
]
